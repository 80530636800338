import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import _ from "lodash"
import Img from "gatsby-image"
import FeaturePost from "../../components/FeaturePost/featurePost"
import SportmarketImg from "../../images/sportmarket.svg"
import TrademateImg from "../../images/trademate.svg"
import BlogCta from "../../images/blog-cta.svg"
import {
  SidebarWrapper,
  SidebarWidger,
  WidgetTitle,
  WidgetContent,
  TagItem,
  CallToActionButton,
  InstagramWrapper,
  InstagramPhoto,
} from "./style"

type SidebarProps = {}

const Sidebar: React.FunctionComponent<SidebarProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 5
      ) {
        edges {
          node {
            excerpt(pruneLength: 300)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD [<span>] MMM [</span>]")
              externalTitle
              description
              tags
              coverAlt
              cover {
                childImageSharp {
                  fluid(maxWidth: 90, maxHeight: 90, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
        group(field: frontmatter___tags) {
          totalCount
          fieldValue
        }
      }
    }
  `)

  const Posts = Data.allMarkdownRemark.edges
  const Tags = Data.allMarkdownRemark.group

  return (
    <SidebarWrapper>

      <SidebarWidger>
        <a
          href="https://app.tradematesports.com/"
          aria-label="Trademate"
          target="_blank"
        >
          <img className='promotionImage' src={TrademateImg} alt="Get Started with Trademate" />
        </a>
        <WidgetContent>
          <p>Trademate users have <b>earned over 5.000.000 EUR in profit</b>, and you can join them.
          Start earning real money beating the bookmakers with the proven methods of value betting now!</p>
          <CallToActionButton href="https://app.tradematesports.com/" target='_blank'
            style={{backgroundColor: 'rgb(255, 91, 96)', fontWeight: 'bold', height: '40px', lineHeight:'40px'}}

          >Get started Now - For Free</CallToActionButton>
        </WidgetContent>
      </SidebarWidger>

      <SidebarWidger>
            <a
                href="https://app.oddshero.io/signup"
                aria-label="Oddshero"
                target="_blank"
            >
                <img className='promotionImage' src={BlogCta} alt="Get started Now - For Free" />
            </a>
            <WidgetContent>
                <p>
                    Our partners at Oddshero applies the proven method of Matched Betting to help you profit from bookmaker bonus offers. You can earn your first 1.000 EUR in less than a month!
                </p>
                <CallToActionButton href="https://app.oddshero.io/signup" target='_blank'>Find out more</CallToActionButton>
            </WidgetContent>
        </SidebarWidger>

      <SidebarWidger>
        <a
          href="https://www.sportmarket.com/?affiliateid=277"
          aria-label="Sportmarket"
          target="_blank"
        >
          <img className='promotionImage' src={SportmarketImg} alt="Visit Sportmarket" />
        </a>
          <WidgetContent>
            <p>We recommend Sportmarket as your Broker/Account to the Asian Bookmakers and Exchanges.</p>
              <CallToActionButton href="https://www.sportmarket.com/?affiliateid=277" target='_blank'>Visit Sportmarket</CallToActionButton>
          </WidgetContent>
      </SidebarWidger>

      <SidebarWidger>
          <WidgetContent>
        <WidgetTitle>Latest Post</WidgetTitle>
        {Posts.map(({ node }: any) => {
          const title = node.frontmatter.externalTitle || node.fields.slug
          //Random Placeholder Color
          const placeholderColors = [
            "#55efc4",
            "#81ecec",
            "#74b9ff",
            "#a29bfe",
            "#ffeaa7",
            "#fab1a0",
            "#e17055",
            "#0984e3",
            "#badc58",
            "#c7ecee",
          ]
          const setColor =
            placeholderColors[
              Math.floor(Math.random() * placeholderColors.length)
            ]

          return (
            <FeaturePost
              key={node.fields.slug}
              title={title}
              image={
                node.frontmatter.cover == null
                  ? null
                  : node.frontmatter.cover.childImageSharp.fluid
              }
              coverAlt={node.frontmatter.coverAlt}
              url={node.fields.slug}
              tags={node.frontmatter.tags}
              placeholderBG={setColor}
            />
          )
        })}
          </WidgetContent>
      </SidebarWidger>

      <SidebarWidger>
          <WidgetContent>
        <WidgetTitle>Tags</WidgetTitle>
        {Tags.map((tag: any) => (
          <TagItem key={tag.fieldValue}>
            <span>#</span>
            <Link to={`/tags/${_.kebabCase(tag.fieldValue)}/`}>
              {tag.fieldValue} <span>({tag.totalCount})</span>
            </Link>
          </TagItem>
        ))}
          </WidgetContent>
      </SidebarWidger>
    </SidebarWrapper>
  )
}

export default Sidebar
